<template>
    <div v-if="me" class="container">
        <StackRouterHeaderBar :title="'소득 인증하기'" :show-title="true" />
        <main class="wrapper">
            <div class="title m-b-8">근로 유형을 선택해주세요</div>
            <div class="required m-b-20">
                <div class="menu" v-for="menu in menus" :key="menu.id" @click="onClickMenu(menu.route)">
                    <span class="f-medium c-black f-16" v-html="menu.name" />
                </div>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    name: 'CertificateIncomesPage',
    mounted() {},
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        menus() {
            return [
                {
                    id: 1,
                    name: '근로소득자',
                    route: 'CertificateEmployeeIncomePage',
                },
                {
                    id: 2,
                    name: '사업소득자/프리랜서/아르바이트',
                    route: 'CertificateBusinessIncomePage',
                },
            ]
        },
        declined() {
            return this.$store.getters.declined
        },
    },
    methods: {
        async onClickMenu(routeName) {
            this.$stackRouter.push({
                name: routeName,
            })
        },
        async save() {
            try {
                await this.$store.dispatch('loadMyVerificationBadges')
                this.$store.dispatch('loadMe')
                this.$store.dispatch('loadSettings')
            } catch (e) {
                console.log(e)
            }
        },
        back() {
            this.$stackRouter.pop()
        },
    },
    created() {
        this.$registerBackHandler(this.back)
    },
    beforeDestroy() {
        this.save()
        this.$unregisterBackHandler()
    },
}
</script>

<style lang="scss" scoped>
.bottom-border {
    border-bottom: 1px solid $grey-02;
}
.container {
    color: #111111;

    .wrapper {
        padding: 24px 16px;
        .title {
            @include spoqa-f-bold;
            font-size: 16px;
            line-height: 26px;
            padding: 30px;
            text-align: center;
        }
        .menu {
            display: flex;
            flex-direction: column;
            border: 1px solid $grey-04;
            padding: 25px;
            margin-bottom: 10px;
            border-radius: 8px;
            font-size: 16px;
            color: black;
        }
    }
}
</style>
